import React from 'react'
import Employee from '../Employee'

const EmpNewLeads = () => {
  return (
    <div>
      <Employee/> 
      EmpNewLeads
    </div>
  )
}

export default EmpNewLeads