// images
import user1 from "../../../assets/images/users/avatar-2.jpg";
import user2 from "../../../assets/images/users/avatar-3.jpg";
import user3 from "../../../assets/images/users/avatar-4.jpg";

interface EmailItems {
  id: number;
  from_name: string;
  from_email: string;
  subject: string;
  teaser: string;
  number_of_reply: number;
  is_important: boolean;
  is_starred?: boolean;
  is_read: boolean;
  time: string;
  date: string;
}

export interface ChatUserTypes {
  id: number;
  name: string;
  avatar?: string;
  variant?: string;
  message: string;
}

const emails: EmailItems[] = [
  {
    id: 1,
    from_name: "Lucas Kriebel (via Twitter)",
    from_email: "ccapeloff0@yandex.ru",
    subject: "Laceration of musc/fasc/tend abdomen, lower back and pelvis",
    teaser:
      "@LucasKriebel - Very cool :) Nicklas, You have a new direct message.",
    number_of_reply: 2,
    is_important: false,
    is_read: false,
    is_starred: true,
    time: "11:49 am",
    date: "May 21",
  },
  {
    id: 2,
    from_name: "Seth Tipton",
    from_email: "stipton1@about.me",
    subject: "Unspecified open wound, left lower leg, initial encounter",
    teaser:
      "Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again!",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    time: "8:44 AM",
    date: "May 16",
  },
  {
    id: 3,
    from_name: "Rafaelita Goutcher",
    from_email: "rgoutcher2@nature.com",
    subject: "Maternal care for hereditary disease in fetus, fetus 5",
    teaser:
      "You've been confirmed! Welcome to the ruling class of the inbox. For your records, here is a copy of the information you submitted to us...",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "7:58 AM",
    date: "May 27",
  },
  {
    id: 4,
    from_name: "Susette Mintrim",
    from_email: "smintrim3@cbsnews.com",
    subject: "Displaced intartic fx l calcaneus, subs for fx w delay heal",
    teaser:
      'Hej Nicklas Sandell! Vi vill bjuda in dig till "First tour 2014", ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige.',
    number_of_reply: 5,
    is_important: false,
    is_read: true,
    time: "9:43 PM",
    date: "May 04",
  },
  {
    id: 5,
    from_name: "Zechariah Ablett",
    from_email: "zablett4@reverbnation.com",
    subject: "Type 1 diabetes with stable prolif diabetic rtnop, left eye",
    teaser: "Will you answer him asap?",
    number_of_reply: 5,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "8:28 AM",
    date: "May 24",
  },
  {
    id: 6,
    from_name: "Genovera Ingamells",
    from_email: "gingamells5@dot.gov",
    subject: "Osseous stenosis of neural canal of cervical region",
    teaser:
      "Eff that place, you might as well stay here with us instead! Sent from my iPhone 4 > 4 mar 2014 at 5:55 pm",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "6:03 PM",
    date: "May 14",
  },
  {
    id: 7,
    from_name: "Gustavus Flockhart",
    from_email: "gflockhart6@omniture.com",
    subject: "Exposure to domestic wiring and appliances, subs encntr",
    teaser:
      "Our top pick for you on Medium this week The Man Who Destroyed America’s Ego",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "3:51 PM",
    date: "May 24",
  },
  {
    id: 8,
    from_name: "Winifred Riddett",
    from_email: "wriddett7@skype.com",
    subject: "Strain of adductor musc/fasc/tend unsp thigh, subs",
    teaser:
      "To create this month's pack, we hosted a party with local musician Jared Mahone here in Columbus, Ohio.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "9:29 PM",
    date: "May 10",
  },
  {
    id: 9,
    from_name: "Land Allum",
    from_email: "lallum8@fastcompany.com",
    subject: "Contact w lifting and transmission devices, NEC",
    teaser:
      "Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen!",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "12:46 PM",
    date: "May 28",
  },
  {
    id: 10,
    from_name: "Salomo Bardnam",
    from_email: "sbardnam9@artisteer.com",
    subject: "Other specified arthritis, elbow",
    teaser: "That's great, see you on Thursday!",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "11:56 AM",
    date: "May 26",
  },
  {
    id: 11,
    from_name: "me, Susanna (11)",
    from_email: "rbushbya@pen.io",
    subject: "Driver of bus injured in nonclsn trnsp acc in traf, subs",
    teaser: "You have been assigned a task by Alex@Work on the board Web.",
    number_of_reply: 5,
    is_important: false,
    is_read: false,
    is_starred: true,
    time: "12:14 PM",
    date: "May 14",
  },
  {
    id: 12,
    from_name: "Rubi Turvie",
    from_email: "rturvieb@studiopress.com",
    subject: "Diffuse TBI w LOC w death due to oth cause bf consc",
    teaser:
      "Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "7:39 PM",
    date: "May 23",
  },
  {
    id: 13,
    from_name: "Ardella Kunze",
    from_email: "akunzec@businesswire.com",
    subject: "Car driver injured in collision w rail trn/veh nontraf, subs",
    teaser: "Nah man sorry i don't. Should i get it?",
    number_of_reply: 5,
    is_important: false,
    is_read: true,
    time: "3:41 AM",
    date: "May 21",
  },
  {
    id: 14,
    from_name: "Miner Engel",
    from_email: "mengeld@usnews.com",
    subject: "Laceration w/o fb of r mid finger w/o damage to nail",
    teaser: "That's just perfect! See you tomorrow.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "5:19 AM",
    date: "May 07",
  },
  {
    id: 15,
    from_name: "Danyette Worviell",
    from_email: "dworvielle@pen.io",
    subject: "Superficial foreign body, left thigh, subsequent encounter",
    teaser:
      "The following items were added to your Stack Exchange global inbox since you last checked it.",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "1:44 AM",
    date: "May 21",
  },
  {
    id: 16,
    from_name: "Allen Millmoe",
    from_email: "amillmoef@hp.com",
    subject: "Complete oblique atypical femoral fracture, right leg, 7thP",
    teaser:
      "Yes ok, great! I'm not stuck in Stockholm anymore, we're making progress.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "11:46 AM",
    date: "May 01",
  },
  {
    id: 17,
    from_name: "Rosita Todaro",
    from_email: "rtodarog@aol.com",
    subject: "Postproc seroma of a nervous sys org fol a nervous sys proc",
    teaser:
      "Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "7:42 AM",
    date: "May 28",
  },
  {
    id: 18,
    from_name: "Alric Balogh",
    from_email: "abaloghh@e-recht24.de",
    subject: "Unsp superficial injury of right little finger, sequela",
    teaser:
      "Alright thanks. I'll have to re-book that somehow, i'll get back to you.",
    number_of_reply: 1,
    is_important: false,
    is_starred: true,
    is_read: true,
    time: "2:18 AM",
    date: "May 27",
  },
  {
    id: 19,
    from_name: "Vic Blower",
    from_email: "vbloweri@behance.net",
    subject: "Corrosion of third degree of trunk",
    teaser:
      "@LucasKriebel - Very cool :) Nicklas, You have a new direct message.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "11:20 PM",
    date: "May 05",
  },
  {
    id: 20,
    from_name: "Martainn Jacques",
    from_email: "mjacquesj@themeforest.net",
    subject: "Other injury of adductor muscle, fascia and tendon of thigh",
    teaser:
      "Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again!",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "2:12 AM",
    date: "May 11",
  },
  {
    id: 21,
    from_name: "me, Peter (5)",
    from_email: "skubatk@japanpost.jp",
    subject: "Sltr-haris Type IV physeal fx upper end of l tibia, sequela",
    teaser:
      "You've been confirmed! Welcome to the ruling class of the inbox. For your records, here is a copy of the information you submitted to us...",
    number_of_reply: 2,
    is_important: false,
    is_read: false,
    time: "10:14 AM",
    date: "May 24",
  },
  {
    id: 22,
    from_name: "Rochette Sproul",
    from_email: "rsproull@digg.com",
    subject: "Acute lymphadenitis of other sites",
    teaser:
      'Hej Nicklas Sandell! Vi vill bjuda in dig till "First tour 2014", ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige.',
    number_of_reply: 5,
    is_important: false,
    is_read: true,
    time: "3:57 AM",
    date: "May 25",
  },
  {
    id: 23,
    from_name: "Timothea Koch",
    from_email: "tkochm@google.co.jp",
    subject: "Superficial frostbite of neck, initial encounter",
    teaser: "Will you answer him asap?",
    number_of_reply: 5,
    is_important: false,
    is_read: true,
    time: "5:06 PM",
    date: "May 14",
  },
  {
    id: 24,
    from_name: "Launce Trent",
    from_email: "ltrentn@si.edu",
    subject: "Leakage of nephrostomy catheter",
    teaser:
      "Eff that place, you might as well stay here with us instead! Sent from my iPhone 4 > 4 mar 2014 at 5:55 pm",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "5:22 PM",
    date: "May 15",
  },
  {
    id: 25,
    from_name: "Camille Verman",
    from_email: "cvermano@hostgator.com",
    subject: "Displ seg fx shaft of l fibula, 7thR",
    teaser:
      "Our top pick for you on Medium this week The Man Who Destroyed America’s Ego",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "6:07 PM",
    date: "May 26",
  },
  {
    id: 26,
    from_name: "Angelita Ida",
    from_email: "aidap@virginia.edu",
    subject: "Luxation of globe",
    teaser:
      "To create this month's pack, we hosted a party with local musician Jared Mahone here in Columbus, Ohio.",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    time: "5:02 AM",
    date: "May 18",
  },
  {
    id: 27,
    from_name: "Emmie Chooter",
    from_email: "echooterq@loc.gov",
    subject: "Pasngr in pk-up/van inj in clsn w 2/3-whl mv in traf, sqla",
    teaser:
      "Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen!",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    time: "4:44 AM",
    date: "May 22",
  },
  {
    id: 28,
    from_name: "Fanya Sandland",
    from_email: "fsandlandr@4shared.com",
    subject: "Antepartum hemorrhage, unspecified, second trimester",
    teaser: "That's great, see you on Thursday!",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    time: "1:28 PM",
    date: "May 27",
  },
  {
    id: 29,
    from_name: "Raynard Corrett",
    from_email: "rcorretts@berkeley.edu",
    subject: "Unsp injury of musc/fasc/tend at thi lev, right thigh, init",
    teaser: "You have been assigned a task by Alex@Work on the board Web.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "1:01 PM",
    date: "May 20",
  },
  {
    id: 30,
    from_name: "Vanda Kirkhouse",
    from_email: "vkirkhouset@tinypic.com",
    subject: "Nondisplaced apophyseal fracture of left femur, init",
    teaser:
      "Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "9:48 AM",
    date: "May 04",
  },
  {
    id: 31,
    from_name: "Paolina O'Nowlan",
    from_email: "ponowlanu@ustream.tv",
    subject: "Drug induced acute pancreatitis",
    teaser: "Nah man sorry i don't. Should i get it?",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "5:31 AM",
    date: "May 07",
  },
  {
    id: 32,
    from_name: "Letizia Fahy",
    from_email: "lfahyv@mac.com",
    subject: "Disloc of distal interphaln joint of l mid finger, sequela",
    teaser: "That's just perfect! See you tomorrow.",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "6:34 PM",
    date: "May 20",
  },
  {
    id: 33,
    from_name: "Buffy Shapira",
    from_email: "bshapiraw@cbc.ca",
    subject: "Congenital herpesviral [herpes simplex] infection",
    teaser:
      "The following items were added to your Stack Exchange global inbox since you last checked it.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "4:29 PM",
    date: "May 24",
  },
  {
    id: 34,
    from_name: "Harp Francesc",
    from_email: "hfrancescx@fc2.com",
    subject: "Subluxation of unsp interphaln joint of unsp finger, subs",
    teaser:
      "Yes ok, great! I'm not stuck in Stockholm anymore, we're making progress.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "4:52 AM",
    date: "May 22",
  },
  {
    id: 35,
    from_name: "Adella Simoes",
    from_email: "asimoesy@vk.com",
    subject: "Partial traum amp of two or more left lesser toes, sequela",
    teaser:
      "Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)",
    number_of_reply: 5,
    is_important: false,
    is_starred: true,
    is_read: true,
    time: "8:36 PM",
    date: "May 10",
  },
  {
    id: 36,
    from_name: "Olivette Rule",
    from_email: "orulez@amazon.co.jp",
    subject: "Unspecified sprain of right index finger",
    teaser:
      "Alright thanks. I'll have to re-book that somehow, i'll get back to you.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "4:35 PM",
    date: "May 19",
  },
  {
    id: 37,
    from_name: "Cornie Whalebelly",
    from_email: "cwhalebelly10@imageshack.us",
    subject: "Toxic effect of oth substances, undetermined, init encntr",
    teaser:
      "@LucasKriebel - Very cool :) Nicklas, You have a new direct message.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "2:22 PM",
    date: "May 08",
  },
  {
    id: 38,
    from_name: "Ruperta Gounel",
    from_email: "rgounel11@goodreads.com",
    subject: "Fracture of alveolus of mandible",
    teaser:
      "Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again!",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "9:39 AM",
    date: "May 18",
  },
  {
    id: 39,
    from_name: "Arabele Robardey",
    from_email: "arobardey12@github.com",
    subject: "Pain in right leg",
    teaser:
      "You've been confirmed! Welcome to the ruling class of the inbox. For your records, here is a copy of the information you submitted to us...",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "7:50 PM",
    date: "May 25",
  },
  {
    id: 40,
    from_name: "Jo Tonbridge",
    from_email: "jtonbridge13@washington.edu",
    subject: "Adverse effect of loop [high-ceiling] diuretics",
    teaser:
      'Hej Nicklas Sandell! Vi vill bjuda in dig till "First tour 2014", ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige.',
    number_of_reply: 4,
    is_important: false,
    is_starred: true,
    is_read: true,
    time: "9:44 AM",
    date: "May 03",
  },
  {
    id: 41,
    from_name: "Blanche Cossington",
    from_email: "bcossington14@biglobe.ne.jp",
    subject: "Unspecified chorioretinal inflammation, right eye",
    teaser: "Will you answer him asap?",
    number_of_reply: 5,
    is_important: true,
    is_read: true,
    is_starred: true,
    time: "6:59 PM",
    date: "May 14",
  },
  {
    id: 42,
    from_name: "Giacinta Belhome",
    from_email: "gbelhome15@hp.com",
    subject: "Displaced oth fx tuberosity of r calcaneus, init for opn fx",
    teaser:
      "Eff that place, you might as well stay here with us instead! Sent from my iPhone 4 > 4 mar 2014 at 5:55 pm",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "6:06 AM",
    date: "May 28",
  },
  {
    id: 43,
    from_name: "Lorain MacGaughy",
    from_email: "lmacgaughy16@mashable.com",
    subject: "Passenger in hv veh injured in clsn w statnry object in traf",
    teaser:
      "Our top pick for you on Medium this week The Man Who Destroyed America’s Ego",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "8:23 AM",
    date: "May 05",
  },
  {
    id: 44,
    from_name: "Lorettalorna Mougel",
    from_email: "lmougel17@friendfeed.com",
    subject: "Synovial hypertrophy, NEC, unsp ankle and foot",
    teaser:
      "To create this month's pack, we hosted a party with local musician Jared Mahone here in Columbus, Ohio.",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "2:06 AM",
    date: "May 18",
  },
  {
    id: 45,
    from_name: "Lucretia Willbraham",
    from_email: "lwillbraham18@bloomberg.com",
    subject: "Nondisp fx of middle third of navicular bone of left wrist",
    teaser:
      "Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen!",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "10:51 PM",
    date: "May 22",
  },
  {
    id: 46,
    from_name: "Otes Kix",
    from_email: "okix19@ted.com",
    subject: "Minor laceration of unspecified carotid artery, init encntr",
    teaser: "That's great, see you on Thursday!",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "4:31 AM",
    date: "May 09",
  },
  {
    id: 47,
    from_name: "Matthaeus Allum",
    from_email: "mallum1a@ehow.com",
    subject: "Gonorrhea compl preg/chldbrth",
    teaser: "You have been assigned a task by Alex@Work on the board Web.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "10:15 PM",
    date: "May 19",
  },
  {
    id: 48,
    from_name: "Honey Clarabut",
    from_email: "hclarabut1b@guardian.co.uk",
    subject: "Displaced Maisonneuve's fracture of right leg, init",
    teaser:
      "Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome.",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "8:10 PM",
    date: "May 16",
  },
  {
    id: 49,
    from_name: "Maure Peres",
    from_email: "mperes1c@psu.edu",
    subject: "Unspecified subluxation of right foot, subsequent encounter",
    teaser: "Nah man sorry i don't. Should i get it?",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "9:11 AM",
    date: "May 01",
  },
  {
    id: 50,
    from_name: "Bink Taggert",
    from_email: "btaggert1d@illinois.edu",
    subject: "Toxic effect of venom of black widow spider, undet, sequela",
    teaser: "That's just perfect! See you tomorrow.",
    number_of_reply: 5,
    is_important: false,
    is_starred: true,
    is_read: true,
    time: "2:10 PM",
    date: "May 04",
  },
  {
    id: 51,
    from_name: "Lannie Kitlee",
    from_email: "lkitlee1e@telegraph.co.uk",
    subject: "Milt op w acc deton onboard marine weapons, civ, sequela",
    teaser:
      "The following items were added to your Stack Exchange global inbox since you last checked it.",
    number_of_reply: 4,
    is_important: true,
    is_read: true,
    time: "1:03 PM",
    date: "May 20",
  },
  {
    id: 52,
    from_name: "Erasmus Ruppel",
    from_email: "eruppel1f@nydailynews.com",
    subject: "Nondisplaced apophyseal fracture of right femur, init",
    teaser:
      "Yes ok, great! I'm not stuck in Stockholm anymore, we're making progress.",
    number_of_reply: 5,
    is_important: false,
    is_read: true,
    time: "5:29 AM",
    date: "May 04",
  },
  {
    id: 53,
    from_name: "Raviv Heinsius",
    from_email: "rheinsius1g@netscape.com",
    subject: "Pregnancy related exhaustion and fatigue, first trimester",
    teaser:
      "Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "7:42 AM",
    date: "May 26",
  },
  {
    id: 54,
    from_name: "Susann Rae",
    from_email: "srae1h@last.fm",
    subject: "Jumping or diving into natural body of water",
    teaser:
      "Alright thanks. I'll have to re-book that somehow, i'll get back to you.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "11:47 AM",
    date: "May 26",
  },
  {
    id: 55,
    from_name: "Babbette Liven",
    from_email: "bliven1i@cdbaby.com",
    subject: "Toxic effect of lead and its compounds, undet, sequela",
    teaser:
      "@LucasKriebel - Very cool :) Nicklas, You have a new direct message.",
    number_of_reply: 1,
    is_important: false,
    is_starred: true,
    is_read: true,
    time: "6:25 AM",
    date: "May 07",
  },
  {
    id: 56,
    from_name: "Mylo Pawfoot",
    from_email: "mpawfoot1j@wikimedia.org",
    subject: "Acute serous otitis media, recurrent, unspecified ear",
    teaser:
      "Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again!",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "11:30 PM",
    date: "May 15",
  },
  {
    id: 57,
    from_name: "Bambie Prendiville",
    from_email: "bprendiville1k@unesco.org",
    subject: "Maternal care for excess fetal growth, third tri, fetus 3",
    teaser:
      "You've been confirmed! Welcome to the ruling class of the inbox. For your records, here is a copy of the information you submitted to us...",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "12:31 AM",
    date: "May 19",
  },
  {
    id: 58,
    from_name: "Noelyn Shotton",
    from_email: "nshotton1l@independent.co.uk",
    subject: "Glaucoma sec to eye trauma, left eye, indeterminate stage",
    teaser:
      'Hej Nicklas Sandell! Vi vill bjuda in dig till "First tour 2014", ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige.',
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "5:07 AM",
    date: "May 07",
  },
  {
    id: 59,
    from_name: "Demetri Lamartine",
    from_email: "dlamartine1m@skyrock.com",
    subject: "Other forms of tularemia",
    teaser: "Will you answer him asap?",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "2:46 AM",
    date: "May 08",
  },
  {
    id: 60,
    from_name: "Leigh Driuzzi",
    from_email: "ldriuzzi1n@flavors.me",
    subject: "Maternal care for Anti-A sensitization, third trimester",
    teaser:
      "Eff that place, you might as well stay here with us instead! Sent from my iPhone 4 > 4 mar 2014 at 5:55 pm",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    time: "6:03 AM",
    date: "May 14",
  },
  {
    id: 61,
    from_name: "Hyacinthie Staziker",
    from_email: "hstaziker1o@furl.net",
    subject: "Age-rel osteopor w crnt path fx, l femr, 7thG",
    teaser:
      "Our top pick for you on Medium this week The Man Who Destroyed America’s Ego",
    number_of_reply: 3,
    is_important: true,
    is_read: true,
    is_starred: true,
    time: "1:49 AM",
    date: "May 22",
  },
  {
    id: 62,
    from_name: "Inga Kubis",
    from_email: "ikubis1p@webmd.com",
    subject: "Oth insomnia not due to a substance or known physiol cond",
    teaser:
      "To create this month's pack, we hosted a party with local musician Jared Mahone here in Columbus, Ohio.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "4:53 PM",
    date: "May 22",
  },
  {
    id: 63,
    from_name: "Berkly Cawston",
    from_email: "bcawston1q@sina.com.cn",
    subject: "Displ Maisonneuve's fx l leg, 7thJ",
    teaser:
      "Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen!",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "8:28 PM",
    date: "May 19",
  },
  {
    id: 64,
    from_name: "Phyllida McLurg",
    from_email: "pmclurg1r@statcounter.com",
    subject: "Nondisp bimalleol fx unsp low leg, 7thF",
    teaser: "That's great, see you on Thursday!",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "6:59 AM",
    date: "May 27",
  },
  {
    id: 65,
    from_name: "Trish Putton",
    from_email: "tputton1s@freewebs.com",
    subject: "Palindromic rheumatism, right wrist",
    teaser: "You have been assigned a task by Alex@Work on the board Web.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "10:56 AM",
    date: "May 28",
  },
  {
    id: 66,
    from_name: "Rana Wurst",
    from_email: "rwurst1t@marketwatch.com",
    subject: "Contusion of left wrist, subsequent encounter",
    teaser:
      "Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome.",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    time: "6:19 PM",
    date: "May 01",
  },
  {
    id: 67,
    from_name: "Weider Soppeth",
    from_email: "wsoppeth1u@t.co",
    subject: "Leucocoria, unspecified eye",
    teaser: "Nah man sorry i don't. Should i get it?",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "3:12 PM",
    date: "May 17",
  },
  {
    id: 68,
    from_name: "Roderic Newbold",
    from_email: "rnewbold1v@hugedomains.com",
    subject: "Stress fracture, hand and fingers",
    teaser: "That's just perfect! See you tomorrow.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "9:43 PM",
    date: "May 03",
  },
  {
    id: 69,
    from_name: "Mead Rawcliff",
    from_email: "mrawcliff1w@techcrunch.com",
    subject: "Nondisp fx of neck of oth metacarpal bone, init for clos fx",
    teaser:
      "The following items were added to your Stack Exchange global inbox since you last checked it.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "8:40 PM",
    date: "May 24",
  },
  {
    id: 70,
    from_name: "Gardener Yea",
    from_email: "gyea1x@sakura.ne.jp",
    subject: "Postnasal drip",
    teaser:
      "Yes ok, great! I'm not stuck in Stockholm anymore, we're making progress.",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "11:51 PM",
    date: "May 18",
  },
  {
    id: 71,
    from_name: "Koren Kamien",
    from_email: "kkamien1y@soup.io",
    subject: "Inj oth musc/tend post grp at low leg level, right leg, sqla",
    teaser:
      "Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)",
    number_of_reply: 4,
    is_important: true,
    is_read: true,
    time: "8:14 AM",
    date: "May 08",
  },
  {
    id: 72,
    from_name: "Rivalee Shillaker",
    from_email: "rshillaker1z@census.gov",
    subject: "Partial traumatic amputation of l low leg, level unsp, subs",
    teaser:
      "Alright thanks. I'll have to re-book that somehow, i'll get back to you.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "5:52 PM",
    date: "May 22",
  },
  {
    id: 73,
    from_name: "Bernardina Arnould",
    from_email: "barnould20@apple.com",
    subject: "Inj flexor musc/fasc/tend left thumb at forearm level",
    teaser:
      "@LucasKriebel - Very cool :) Nicklas, You have a new direct message.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "7:25 PM",
    date: "May 12",
  },
  {
    id: 74,
    from_name: "Evita Merck",
    from_email: "emerck21@etsy.com",
    subject: "Malignant neoplasm of short bones of left lower limb",
    teaser:
      "Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again!",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "9:04 AM",
    date: "May 15",
  },
  {
    id: 75,
    from_name: "Hagen Reedy",
    from_email: "hreedy22@psu.edu",
    subject: "Unsp injury of muscle, fascia and tendon of pelvis, subs",
    teaser:
      "You've been confirmed! Welcome to the ruling class of the inbox. For your records, here is a copy of the information you submitted to us...",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    is_starred: true,
    time: "4:37 PM",
    date: "May 10",
  },
  {
    id: 76,
    from_name: "Carter Harme",
    from_email: "charme23@noaa.gov",
    subject: "Ultralt/microlt/pwr-glider accident injuring occupant",
    teaser:
      'Hej Nicklas Sandell! Vi vill bjuda in dig till "First tour 2014", ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige.',
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "7:57 AM",
    date: "May 13",
  },
  {
    id: 77,
    from_name: "Shep Pinches",
    from_email: "spinches24@whitehouse.gov",
    subject: "Unsp injury of unsp external jugular vein, init encntr",
    teaser: "Will you answer him asap?",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "8:13 AM",
    date: "May 20",
  },
  {
    id: 78,
    from_name: "Wolf Rattenberie",
    from_email: "wrattenberie25@newsvine.com",
    subject: "Contact with sword or dagger, undetermined intent, sequela",
    teaser:
      "Eff that place, you might as well stay here with us instead! Sent from my iPhone 4 > 4 mar 2014 at 5:55 pm",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "4:54 PM",
    date: "May 18",
  },
  {
    id: 79,
    from_name: "Harman Dow",
    from_email: "hdow26@vistaprint.com",
    subject: "Otitis externa in oth diseases classd elswhr, bilateral",
    teaser:
      "Our top pick for you on Medium this week The Man Who Destroyed America’s Ego",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "1:12 AM",
    date: "May 22",
  },
  {
    id: 80,
    from_name: "Leodora Passmore",
    from_email: "lpassmore27@cbsnews.com",
    subject: "Puncture wound w/o foreign body of unsp shoulder, subs",
    teaser:
      "To create this month's pack, we hosted a party with local musician Jared Mahone here in Columbus, Ohio.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "6:35 AM",
    date: "May 23",
  },
  {
    id: 81,
    from_name: "Aarika Cornthwaite",
    from_email: "acornthwaite28@amazonaws.com",
    subject: "Unspecified superficial injury of right shoulder",
    teaser:
      "Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen!",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    time: "6:31 PM",
    date: "May 17",
  },
  {
    id: 82,
    from_name: "Binny Gianinotti",
    from_email: "bgianinotti29@wp.com",
    subject: "Poisoning by other fibrinolysis-affecting drugs, assault",
    teaser: "That's great, see you on Thursday!",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "6:54 AM",
    date: "May 19",
  },
  {
    id: 83,
    from_name: "Renelle Youson",
    from_email: "ryouson2a@comsenz.com",
    subject: "Pedl cyc driver inj in clsn w nonmtr vehicle in traf, init",
    teaser: "You have been assigned a task by Alex@Work on the board Web.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "3:27 AM",
    date: "May 16",
  },
  {
    id: 84,
    from_name: "Mommy McGreal",
    from_email: "mmcgreal2b@tripod.com",
    subject: "Inj intrinsic musc/fasc/tend l little finger at wrs/hnd lv",
    teaser:
      "Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "7:39 AM",
    date: "May 21",
  },
  {
    id: 85,
    from_name: "Berne Spaduzza",
    from_email: "bspaduzza2c@naver.com",
    subject: "Morquio B mucopolysaccharidoses",
    teaser: "Nah man sorry i don't. Should i get it?",
    number_of_reply: 1,
    is_important: false,
    is_starred: true,
    is_read: true,
    time: "9:41 PM",
    date: "May 11",
  },
  {
    id: 86,
    from_name: "Tandi Maceur",
    from_email: "tmaceur2d@surveymonkey.com",
    subject: "Oth comp of fb acc left in body fol punctr/cath, sequela",
    teaser: "That's just perfect! See you tomorrow.",
    number_of_reply: 5,
    is_important: false,
    is_read: true,
    time: "10:01 PM",
    date: "May 01",
  },
  {
    id: 87,
    from_name: "Miller Winser",
    from_email: "mwinser2e@xing.com",
    subject: "Attention-deficit hyperactivity disorder, combined type",
    teaser:
      "The following items were added to your Stack Exchange global inbox since you last checked it.",
    number_of_reply: 5,
    is_important: false,
    is_read: true,
    time: "2:28 PM",
    date: "May 07",
  },
  {
    id: 88,
    from_name: "Simone Cheney",
    from_email: "scheney2f@bravesites.com",
    subject: "Prsn brd/alit a car inj in clsn w nonmtr vehicle, sequela",
    teaser:
      "Yes ok, great! I'm not stuck in Stockholm anymore, we're making progress.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "8:58 PM",
    date: "May 17",
  },
  {
    id: 89,
    from_name: "Mollee Warrack",
    from_email: "mwarrack2g@istockphoto.com",
    subject: "Asphyx due to smothr under another person's body, acc, sqla",
    teaser:
      "Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "2:07 AM",
    date: "May 21",
  },
  {
    id: 90,
    from_name: "Charmain Cicchetto",
    from_email: "ccicchetto2h@noaa.gov",
    subject: "Burn of third degree of lower back, subsequent encounter",
    teaser:
      "Alright thanks. I'll have to re-book that somehow, i'll get back to you.",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "6:47 PM",
    date: "May 02",
  },
  {
    id: 91,
    from_name: "Alisa Merriment",
    from_email: "amerriment2i@vkontakte.ru",
    subject: "Disp fx of lateral condyle of l tibia, 7thQ",
    teaser:
      "Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen!",
    number_of_reply: 4,
    is_important: false,
    is_read: true,
    time: "5:58 AM",
    date: "May 25",
  },
  {
    id: 92,
    from_name: "Cris Rosborough",
    from_email: "crosborough2j@fema.gov",
    subject: "Pnctr w fb of r bk wl of thorax w/o penet thor cavity, init",
    teaser: "That's great, see you on Thursday!",
    number_of_reply: 4,
    is_important: false,
    is_starred: true,
    is_read: true,
    time: "7:32 PM",
    date: "May 06",
  },
  {
    id: 93,
    from_name: "Elvera Meigh",
    from_email: "emeigh2k@ask.com",
    subject: "Corrosion of first degree of left foot",
    teaser: "You have been assigned a task by Alex@Work on the board Web.",
    number_of_reply: 1,
    is_important: false,
    is_read: true,
    time: "9:19 AM",
    date: "May 13",
  },
  {
    id: 94,
    from_name: "Naomi Deane",
    from_email: "ndeane2l@weibo.com",
    subject: "Other specified postprocedural states",
    teaser:
      "Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome.",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "2:21 PM",
    date: "May 01",
  },
  {
    id: 95,
    from_name: "Petunia Crossfeld",
    from_email: "pcrossfeld2m@ebay.co.uk",
    subject: "Driver of bus injured in clsn w statnry object nontraf, init",
    teaser: "Nah man sorry i don't. Should i get it?",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "1:53 PM",
    date: "May 29",
  },
  {
    id: 96,
    from_name: "Valenka Meiklejohn",
    from_email: "vmeiklejohn2n@redcross.org",
    subject: "Poisoning by local astringents/detergents, self-harm, subs",
    teaser: "That's just perfect! See you tomorrow.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "7:49 AM",
    date: "May 12",
  },
  {
    id: 97,
    from_name: "Sibby Fieldgate",
    from_email: "sfieldgate2o@ca.gov",
    subject: "Burn of unspecified degree of left scapular region",
    teaser:
      "The following items were added to your Stack Exchange global inbox since you last checked it.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "9:51 PM",
    date: "May 22",
  },
  {
    id: 98,
    from_name: "Garret Irvine",
    from_email: "girvine2p@furl.net",
    subject: "Other superficial bite of hand of left hand, init encntr",
    teaser:
      "Yes ok, great! I'm not stuck in Stockholm anymore, we're making progress.",
    number_of_reply: 2,
    is_important: false,
    is_read: true,
    time: "4:29 AM",
    date: "May 07",
  },
  {
    id: 99,
    from_name: "Amie Steinor",
    from_email: "asteinor2q@myspace.com",
    subject: "Congenital aphakia",
    teaser:
      "Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)",
    number_of_reply: 3,
    is_important: false,
    is_read: true,
    time: "6:13 PM",
    date: "May 10",
  },
  {
    id: 100,
    from_name: "Hyacinthie Hacksby",
    from_email: "hhacksby2r@ft.com",
    subject: "Burn of 3rd deg mu left fingers (nail), inc thumb, subs",
    teaser:
      "Alright thanks. I'll have to re-book that somehow, i'll get back to you.",
    number_of_reply: 5,
    is_important: false,
    is_read: true,
    time: "11:04 PM",
    date: "May 21",
  },
];

const chatUsers: ChatUserTypes[] = [
  {
    id: 1,
    name: "Johnny",
    avatar: user1,
    message: "Neque porro quisquam est",
  },
  {
    id: 2,
    name: "Bryan",
    avatar: user2,
    message: "For science, music, sport, etc",
  },
  {
    id: 3,
    name: "Tracy",
    variant: "success",
    message: "To an English person, it will seem like simplified",
  },
  {
    id: 4,
    name: "Thomas",
    avatar: user3,
    message: "To achieve this, it would be necessary",
  },
  {
    id: 5,
    name: "David",
    variant: "danger",
    message: "If several languages coalesce",
  },
];

export { emails, chatUsers };
